:root {
	--menu-background: rgba(255, 255, 255, 0.08);
	--border-color: rgba(255, 255, 255, 0.2);
	--active-border-color: #0f9347;
	--font-color: #cdd3e6;
	--active-font-color: #fff;
}

.menu__section {
	padding-top: 120px;
	background-image: url('../../assets/Menu.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;

	.card {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 20px;
	}
}

.menu__tabs {
	.nav-wrapper {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
	.nav-tabs {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 50px;
		display: flex;
		justify-content: space-between;

		.nav-link {
			border-radius: 50px;
			color: var(--font-color);
			font-size: 14px;
			font-family: var(--font-family) !important;
			padding: 8px 1rem;
		}

		.active {
			border-color: var(--active-border-color);
			background: var(--active-border-color);
			padding: 8px 1rem;
			font-weight: 600;
			color: var(--active-font-color);
		}
	}
	.dropdown-menu {
		width: 100%;
		background: #161922;
		color: #bbbb;
		border: 1px solid var(--border-color);
	}

	.dropdown-item.active,
	.dropdown-item.active:focus,
	.dropdown-item.active:hover {
		background-color: var(--active-border-color);
	}

	.btn.dropdown-toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
	}
}

.dropdown-toggle {
	border-radius: 50px;
	.btn {
		background: '#fff';
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 50px;
		font-family: var(--font-family);
		padding: 8px 1rem;
	}
}

@media only screen and (max-width: 768px) {
	.menu__tabs {
		.nav-tabs {
			flex-wrap: nowrap;
			overflow-x: scroll;
			justify-content: start;
			padding: 10px 0;

			.nav-link {
				font-size: 0.9rem;
			}
		}

		height: auto;
		padding-bottom: 20px;
	}
}

.cursor-pointer {
	cursor: pointer;
}
