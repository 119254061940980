.all__section {
	padding-top: 120px;

	.form-control,
	.input-group-text {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 20px;
		height: 48px;
		color: #bbbb !important;

		&::placeholder {
			color: #bbbb;
			letter-spacing: 1px;
		}
		&:focus {
			outline: none;
			box-shadow: none;
			border: 1px solid var(--active-border-color);
		}
	}

	.react-calendar {
		width: 300px;
		max-width: 100%;
		border: 1px solid #ccc;
		border-radius: 5px;
		font-family: Arial, sans-serif;
	}

	.react-calendar__navigation {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		background-color: #f0f0f0;
	}

	.react-calendar__navigation button {
		background-color: transparent;
		border: none;
		cursor: pointer;
		font-size: 16px;
		outline: none;
	}

	.react-calendar__navigation button:disabled {
		color: #ccc;
	}

	.react-calendar__navigation button:hover {
		color: #0f9347;
	}

	.react-calendar__navigation button[disabled]:hover {
		cursor: not-allowed;
	}

	.react-calendar__month-view__weekdays {
		display: flex;
		justify-content: space-around;
		margin-bottom: 5px;
		padding: 0 5px;
	}

	.react-calendar__month-view__weekdays__weekday {
		font-weight: bold;
		color: #333;
	}

	.react-calendar__month-view__days {
		display: flex;
		flex-wrap: wrap;
	}

	.react-calendar__tile {
		flex: 1 0 calc(100% / 7);
		max-width: calc(100% / 7);
		padding: 5px;
		text-align: center;
		border: 1px solid transparent;
		transition: background-color 0.3s;
	}

	.react-calendar__tile--active {
		background-color: #0f9347;
		color: #fff;
		border-color: #0f9347;
		// border-radius: 100%;
	}

	.react-calendar__tile--now {
		background-color: #f0f0f0;
	}

	.react-calendar__tile--rangeStart,
	.react-calendar__tile--rangeEnd {
		background-color: #0f9347;
		color: #fff;
	}

	.react-calendar__tile--rangeStart:enabled:hover,
	.react-calendar__tile--rangeEnd:enabled:hover {
		background-color: #0f9347;
	}

	.allorders__buttons {
		display: flex;
		gap: 10px;

		button {
			border-radius: 50px;
			padding: 10px 20px;
			font-size: 16px;
			border: none;
			background-color: #f0f0f0;
			cursor: pointer;
			transition:
				background-color 0.3s,
				color 0.3s;

			&:hover {
				background-color: #e0e0e0;
			}

			&.active {
				background-color: #0f9347;
				color: white;
			}
		}
	}

	table {
		th {
			background: rgba(252, 252, 253, 0.16);
		}
	}
}
