.food__truck__section {
	height: 100vh;
	padding-top: 100px;

	@media (max-width: 768px) {
		height: auto;
		padding-bottom: 20px;
	}

	.text-separator {
		width: 2px;
		height: 30px;
		background: white;
	}
}
