.benefitsCard__section {
	h6 {
		font-size: 18px;
	}

	p {
		font-size: 14px;
	}

	.card {
		border: 0;
	}
}
