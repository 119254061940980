.btn {
	&.order__btn {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0.5em 1.5em;
		border: none;
		background-color: #0f9347;
		color: #ffffff;
		font-size: 14px;
		cursor: pointer;
		border-radius: 50px;

		&:hover {
			background-color: darken(#0f9347, 10%);
		}

		.order-icon {
			margin-right: 0.5em;
			width: 20px;
			height: auto;
		}
	}

	&.order__btn__outline {
		border: 1px solid#0f9347;
		padding: 0.5em 1.5em;
		color: #0f9347;
		border-radius: 50px;
	}
}
