@import 'bootstrap/dist/css/bootstrap.min.css';

@font-face {
	font-family: 'Recoleta';
	src: url('./assets/fonts/Recoleta-RegularDEMO.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

body {
	margin: 0;
	font-family: 'Recoleta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}