:root {
	--menu-background: rgba(255, 255, 255, 0.03);
	--border-color: rgba(255, 255, 255, 0.2);
	--active-border-color: #0f9347;
	--font-color: #cdd3e6;
	--active-font-color: #fff;
}
$background-image-path: '../../assets/subcriptionBg.png';

.login__section {
	height: 80vh;
	background-image: linear-gradient(rgba(22, 25, 34, 0.5), rgba(22, 25, 34, 0.5)), url($background-image-path);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	// background-color: rgba(22, 25, 34, 0.5);

	label {
		color: var(--font-color);
	}

	.center-container {
		display: flex;
		flex-direction: column;
		background-color: var(--menu-background);
		justify-content: center;
		align-items: center;
	}

	.separator {
		display: flex;
		align-items: center;
		text-align: center;

		.divider {
			flex: 1;
			height: 1px;
			background: #fff;
			border: none;
		}

		.separator-text {
			padding: 0 10px;
			font-weight: bold;
		}
	}

	.card {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 20px;
	}
	%form-input-base {
		background: var(--menu-background);
		color: #bbbb;
		border: 1px solid var(--border-color);
		height: 48px;
		&:focus {
			outline: none;
			box-shadow: none;
		}
		&::placeholder {
			color: #bbbb;
			letter-spacing: 1px;
		}
	}

	.input-group-text {
		border-radius: 20px 0px 0px 20px;
	}

	.form-control {
		border-radius: 0px 20px 20px 0px;
		letter-spacing: 3px;
	}
	.phone-subcontainer {
		.form-control,
		.input-group-text,
		input {
			@extend %form-input-base;
		}
	}

	.input-group-text {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.otp-subcontainer {
		input {
			background: var(--menu-background);
			color: #bbbb;
			border: 1px solid var(--border-color);
			height: 64px !important;
			display: flex !important;
			justify-content: space-between !important;
			width: 100% !important;
			border-radius: 50%;
			&:last-child {
				margin-right: 0 !important;
			}

			&:focus {
				outline: none;
				box-shadow: none;
				border: 1px solid var(--active-border-color);
			}

			@media (max-width: 768px) {
				height: 40px !important;
				width: 40px !important;
			}
		}

		.cart__btn__outline {
			border: 1px solid #0f9347;
			padding: 0.5em 1.5em;
			color: #0f9347;
			border-radius: 50px;
		}
	}

	.google__btn__outline {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 20px;
		height: 48px;
	}
}
