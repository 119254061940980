$background-image-path: '../../assets/subcriptionBg.png';

.payment__section {
	padding-top: 120px;
	background-image: url($background-image-path);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.contact__form__section {
	.card {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 20px;

		.btn {
			&.order__btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding: 0.5em 1.5em;
				border: none;
				background-color: var(--active-border-color);
				color: #ffffff;
				font-size: 1rem;
				cursor: pointer;
				border-radius: 50px;
				height: 48px;
				font-weight: bold;

				&:hover {
					background-color: darken(#0f9347, 10%);
				}

				.order-icon {
					margin-right: 0.5em;
					width: 20px;
					height: auto;
				}
			}
		}

		.form-label,
		.form-check-label {
			color: #b8b8b8;
		}

		.form-control,
		.input-group-text {
			background: var(--menu-background);
			color: var(--active-font-color);
			border: 1px solid var(--border-color);
			border-radius: 20px;
			height: 48px;
			color: #bbbb !important;

			&::placeholder {
				color: #bbbb;
				letter-spacing: 1px;
			}

			&:focus {
				outline: none;
				box-shadow: none;
				border: 1px solid var(--active-border-color);
			}
		}

		.form-check-input {
			border: 2px solid #b8b8b8;
			background-color: transparent;
			transition: all 0.3s;
		}

		.form-check-input:checked {
			border-color: var(--active-border-color);
			background-color: var(--active-border-color);
		}
	}
}
