:root {
	--menu-background: rgba(255, 255, 255, 0.03);
	--border-color: rgba(255, 255, 255, 0.2);
	--active-border-color: #0f9347;
	--font-color: #cdd3e6;
	--active-font-color: #fff;
}

.cart__section {
	padding-top: 140px;

	.card {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 20px;

		.btn {
			&.order__btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding: 0.5em 1.5em;
				border: none;
				background-color: #0f9347;
				color: #ffffff;
				font-size: 14px;
				cursor: pointer;
				border-radius: 50px;
				height: 48px;

				&:hover {
					background-color: darken(#0f9347, 10%);
				}

				.order-icon {
					margin-right: 0.5em;
					width: 20px;
					height: auto;
				}
			}

			&.order__btn__outline {
				border: 1px solid#0f9347;
				padding: 0.5em 1.5em;
				color: #0f9347;
				border-radius: 50px;
			}
		}

		input,
		.input-group-text {
			background: var(--menu-background);
			color: var(--active-font-color);
			border: 1px solid var(--border-color);
			border-radius: 20px;
			height: 48px;
			color: #bbbb !important;

			&::placeholder {
				color: #bbbb;
				letter-spacing: 1px;
			}
			&:focus {
				outline: none;
				box-shadow: none;
				border: 1px solid var(--active-border-color);
			}
		}

		.form-check-input {
			border: 2px solid #b8b8b8;
			background-color: transparent;
			transition: all 0.3s;
		}

		.form-check-input:checked {
			border-color: var(--active-border-color);
			background-color: var(--active-border-color);
		}
	}
}

.cartempty__section {
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
