$background-color: #161922;
$logo-width: 70px;
$text-color-orange: orange;
$text-color-active: white;
$navbar-toggler-icon-bg: #fff;
$toggler-icon-transform-time: 0.4s;

@mixin transition($property: all, $duration: 0.2s, $timing-function: ease) {
	transition: $property $duration $timing-function;
}

.nav__section {
	background-color: $background-color;
	display: flex;
	justify-content: space-between;
	align-items: center;

	// .dropdown-toggle {
	// 	cursor: pointer;

	// 	&::after {
	// 		display: none;
	// 	}
	// }

	.logo {
		width: $logo-width;
		margin-right: auto;
	}

	.navbar-nav {
		display: flex;

		.nav-item {
			margin-left: 20px;

			.order_btn_profile {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
			}

			.nav-link {
				text-decoration: none;

				&.text__orange {
					color: $text-color-orange;
				}

				&.active {
					color: $text-color-active;
					text-decoration: underline;
				}
			}
		}
	}

	.btn {
		&.cart__btn {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0.5em 1.5em;
			border: none;
			background-color: #0f9347;
			color: #ffffff;
			font-size: 14px;
			cursor: pointer;
			border-radius: 50px;
			margin-left: 20px;

			&:hover {
				background-color: darken(#0f9347, 10%);
			}

			.cart-quantity {
				margin-left: 0.5em;
				background: #ad8465;
				color: #232227;
				border: 1px solid lightgray;
				height: 20px;
				width: 20px;
				border-radius: 50%;
				display: block;
			}

			.cart-icon {
				margin-right: 0.5em;
				width: 20px;
				height: auto;
			}
		}

		&.cart__btn__outline {
			border: 1px solid #0f9347;
			padding: 0.5em 1.5em;
			color: #0f9347;
			border-radius: 50px;
		}
	}

	.navbar-toggler {
		border: none;
		background-color: transparent;

		&:focus {
			box-shadow: none;
		}

		.toggler-icon {
			display: block;
			width: 25px;
			height: 3px;
			background-color: $navbar-toggler-icon-bg;
			margin: 5px 0;
			@include transition(background-color, $toggler-icon-transform-time);
		}

		&[aria-expanded='true'] {
			.toggler-icon:nth-of-type(1),
			.toggler-icon:nth-of-type(3) {
				@include transition(transform, $toggler-icon-transform-time);
			}

			.toggler-icon:nth-of-type(1) {
				transform: rotate(45deg) translate(5px, 5px);
			}

			.toggler-icon:nth-of-type(2) {
				opacity: 0;
			}

			.toggler-icon:nth-of-type(3) {
				transform: rotate(-45deg) translate(7px, -6px);
			}
		}
	}

	@media (max-width: 768px) {
		.navbar-collapse {
			position: fixed;
			top: 70px;
			left: 0;
			width: 100%;
			height: 100vh;
			background-color: $background-color;
			display: flex;
			flex-direction: column;
			justify-content: flex-start; // Align items to the start of the column
			padding: 20px; // Add padding to move content away from edges
			z-index: 999;
			transform: translateY(-100%);
			opacity: 0;
			visibility: hidden;
			@include transition(transform 0.3s, opacity 0.3s, visibility 0.3s);

			&.show {
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
			}
		}

		.navbar-nav {
			width: 100%; // Take full width
			padding: 10px 20px; // Add padding for spacing
			display: flex;
			flex-direction: column; // Stack items vertically
			align-items: center !important; // Align links to the left
		}

		.nav-link {
			width: 100%; // Ensure full clickable width
			padding: 10px 20px; // Add padding for spacing
			margin: 5px 0;
			text-align: left; // Align text to the left
			font-size: 1rem; // Adjust font size for better readability

			&.active {
				color: $text-color-active;
				text-decoration: underline;
			}
		}
	}

	.dropdown {
		position: relative;
		.dropdown-menu {
			width: 150px;
			backdrop-filter: blur(20px);
			-webkit-backdrop-filter: blur(20px);
			border-radius: 20px;
			background: #0f9347;
			box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
			padding: 10px;

			// Adjust the alignment to fix the issue
			left: auto; // Ensure it's not pushed to the right by default.
			right: 0; // Align to the parent container.
			transform: translateX(0); // Remove any unintended horizontal shifts.

			.dropdown-item {
				color: #fff !important;

				&:hover {
					background: transparent !important;
				}
			}
		}
	}
}
