.tags__green {
	border: 1px solid rgba(15, 147, 71, 0.2);
	border-radius: 50px;
	padding: 0.5rem 1.5rem;
	color: #0f9347;
	font-size: 12px;
	background: rgba(15, 147, 71, 0.08);
	font-weight: bold;
}
.tags__primary {
	color: #fff;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 50px;
	padding: 0.5rem 1.5rem;
	font-size: 12px;
	background: rgba(255, 255, 255, 0.08);
	font-weight: bold;
}
