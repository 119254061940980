@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
	font-family: 'DM Sans', sans-serif;
	overflow-x: hidden;
	background-color: #161922;
}

.text__light__white {
	color: #f1f1f1;
}
.text__green {
	color: #0f9347;
}

.text__orange {
	color: #ad8465;
}

.text__gray {
	color: #dfdfdf;
}

.text__gray__secondary {
	color: #4e4e4e;
}

.text__gray__three {
	color: #b8b8b8;
}

.text__black {
	color: #232227;
}
