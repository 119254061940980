.services__section {
	background-color: #161922;
	height: 100vh;

	img {
		object-fit: cover;
		height: 100vh;
		width: auto;
	}

	@media (max-width: 768px) {
		height: auto;
		padding-bottom: 20px;

		img {
			height: auto;
		}
	}
}
