.truck__section {
	padding-top: 100px;
	@media (max-width: 768px) {
		h1 {
			font-size: 16px;
		}
		img {
			height: 60vh;
		}
	}
}
