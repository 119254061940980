:root {
	--menu-background: rgba(255, 255, 255, 0.08);
	--border-color: rgba(255, 255, 255, 0.2);
	--active-border-color: #0f9347;
	--font-color: #cdd3e6;
	--active-font-color: #fff;
}

.order__section {
	padding-top: 140px;

	.card {
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
		border-radius: 20px;
	}
	.dropdown-menu {
		background: #161922;
		color: #bbbb;
		border: 1px solid var(--border-color);
		width: 200px;
	}

	.dropdown-item.active,
	.dropdown-item.active:focus,
	.dropdown-item.active:hover {
		background-color: var(--active-border-color);
	}

	.btn.dropdown-toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--menu-background);
		color: var(--active-font-color);
		border: 1px solid var(--border-color);
	}
	.dropdown-toggle {
		width: 200px;
		border-radius: 50px;
		.btn {
			background: '#fff';
			color: var(--active-font-color);
			border: 1px solid var(--border-color);
			border-radius: 50px;
			font-family: var(--font-family);
			padding: 8px 1rem;
		}
	}
}
